/* style */
html {
  font-size:16px;
}

@media (max-width: 544px) {
  html {
    font-size:14px;
  }
}

#root {
  font-family: "M PLUS 1p","Hiragino Kaku Gothic Pro","Hiragino Sans",YuGothic,"Meiryo","MS Gothic", sans-serif
}

.btn {
  height: 3.2rem;
}

a {
  text-decoration: none;
}

/* animation */

.transition-1s {
  transition-duration: 1s;
}

.transition-500ms {
  transition-duration: 0.5s;
}
.cursor-pointer {
  cursor: pointer;
}


.animation-slide-down {
  animation: animation-slide-down-frames 2s ease infinite;
}

@keyframes animation-slide-down-frames {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  30% {
    opacity: 0;
    transform: translateY(-20px);
  }
  70% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

.animation-slide-in {
  animation: animation-slide-in-frames 0.5s ease 1;
}

@keyframes animation-slide-in-frames {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animation-on-cursor:hover {
  transform: scale(0.95);
  transition-duration: 0.2s;
}

