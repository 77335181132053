.animation-border-bottom:after {
  transition: all 0.25s ease;
  content: "";
  display: block;
  width: 0%;
  margin-top: 0.3rem;
  border-bottom: 1px solid rgb(101, 166, 3);
}

.animation-border-bottom:hover::after {
  width: 85%;
}
